import React, { useEffect, useState } from "react";
import BlogPostSidebarWrapper from './blogPostSidebar.style'

const BlogPostSidebar = (props) => {

    const [expand, setExpand] = useState(false);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () =>
        window.pageYOffset > 200 ? setScroll(true) : setScroll(false)
      );
    }, [ ]);
    
    return (
        <BlogPostSidebarWrapper className={`post-sidebar ${scroll ? "scrolled" : ""}`}>
            <div dangerouslySetInnerHTML={{ __html: props.table }}></div>
        </BlogPostSidebarWrapper>
    )
}

export default BlogPostSidebar
