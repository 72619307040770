import styled from 'styled-components';

const BlogPostSidebarWrapper = styled.div`
    /* @media(min-width:992px){
        width:250px;
        position:absolute;
        top:0;            
    }
    &.scrolled{
        @media(min-width:992px){
            position:fixed;
            top:200px;
        }

    } */
    .table-of-contents{
        padding: 30px 30px 0 0;
        margin-bottom:40px;
        border: 1px dashed #ccc;
        margin-left: 5px;
        ul{
            li{            
                font-size:14px;
                line-height:26px;          
                list-style-type:none;  
                &:before {
                    opacity:0.25;
                    content: "#  ";
                    padding-right:2px;
                    font-weight:bold;
                    color: ${props => props.theme.headingColor ? props.theme.headingColor : '#333333'};          
                }
                a{                
                    color: ${props => props.theme.black ? props.theme.black : '#333333'};
                    transition:all .1s ease-in-out;

                    &:hover{
                        color: ${props => props.theme.secondaryColor ? props.theme.secondaryColor : '#333333'};
                        text-decoration:underline;                    
                    }
                }
            }
        }
    }

`;

export default BlogPostSidebarWrapper;