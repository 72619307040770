import React from 'react'
import SocialShareStyle from './socialsharing.style'

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    WhatsappShareButton,
    FacebookShareButton

} from "react-share";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const SocialShare = ({ url, title, summary, source, hashtags }) => {

    const trackEvent = (type) => {
        trackCustomEvent({
            category: 'share',
            action: "click",
            label: source || 'post',
            value: type
        });
    }

    return (

        <SocialShareStyle>
            <LinkedinShareButton onClick={() => trackEvent('linkedIn')} url={url} title={title}>
                <LinkedinIcon size={32} round />
            </LinkedinShareButton>

            <EmailShareButton onClick={() => trackEvent('email')} url={url} subject={title} body={'Hey, schau mal was ich gefunden habe:\n\n'}>
                <EmailIcon size={32} round />
            </EmailShareButton>

            <FacebookShareButton onClick={() => trackEvent('facebook')} url={url} title={title}>
                <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton onClick={() => trackEvent('twitter')} url={url} title={title} hashtags={hashtags}>
                <TwitterIcon size={32} round />
            </TwitterShareButton>

            <WhatsappShareButton onClick={() => trackEvent('whatsapp')} url={url} title={title}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>


        </SocialShareStyle>




    )
}

export default SocialShare
