import { graphql } from "gatsby";
import Img from 'gatsby-image'
import React, { useState, useEffect } from "react";
import BlogPostWrapper from "./blogPost.style";
import Layout from "../../UI/Layout/layout";
import SEO from "../../seo";
import PageHeader from "../../UI/PageHeader/index";
import { Row, Col, Container } from "../../UI/Grid/index";
import BlogPostSidebar from "./Blog-post-sidebar/index";
import SocialShare from "../../Social-sharing";
import ReactMarkdown from "react-markdown"
import ReadingProgress from 'react-reading-progress'
import Contact from "../../Contact";

const BlogPost = (props) => {

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () =>
      window.pageYOffset > 50 ? setScroll(true) : setScroll(false)
    );
  }, []);


  const post = props.data.post;
  const imageSizes = {
    width: post.image ? post.image.childImageSharp.fluid.presentationWidth : 0,
    height: post.image ? post.image.childImageSharp.fluid.presentationHeight : 0
  }

  return (
    <Layout>
      <SEO
        metaTitle={post.title}
        metaDescription={post.description}
        metaImageUrl={post.image ? post.image.childImageSharp.fluid.src : null}
        type={'article'}
        metaModifiedDate={post.updatedAt}
        metaPublishedDate={post.createdAt}
        metaImageSize={imageSizes}
      />
      <BlogPostWrapper>
        {scroll && <ReadingProgress targetEl=".postContent" />}
        <Container>

          <Row className="blog-row">

            <Col xs={12} lg={12}>
              <div className="img-wrapper">
                {post.image && <Img fluid={post.image.childImageSharp.fluid} alt={post.image.name} />}

              </div>
              <PageHeader author={{ name: 'Philipp' }} date={post.date} categories={post.categories} title={post.title} />
              <SocialShare url={props.location.href} title={post.title} summary={post.title} source={post.title} />
              <div className="post-inner">
                <BlogPostSidebar table={post.content_table} />
                <div className="blog-post-wrapper">

                  <div className="postContent" dangerouslySetInnerHTML={{ __html: post.content_html }}></div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </BlogPostWrapper>
      <SocialShare url={props.location.href} title={post.title} summary={post.title} source={post.title} />

      <Contact header={post.contact_text ? post.contact_text : "Du brauchst Entscheidungshilfe bei deinem Projekt? Sprich mich einfach an!"}></Contact>
    </Layout>
  );
};


export default BlogPost;

export const query = graphql`
  query($slug: String) {
    post: strapiArticle(slug: {eq: $slug}) {
      date: createdAt(locale: "de", formatString: "DD. MMMM YYYY")
      createdAt
      updatedAt
      contact_text
      description: Meta_description
      categories {
        title
      }
      tags {
        title
      }
      content_table
      content
      content_html
      title
      image {        
        name
        childImageSharp {
          fluid(maxWidth:1140) {
            presentationHeight
            presentationWidth
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
