import styled from 'styled-components';
import Icon from '../../../assets/images/icons/quotes.svg';

const BlogPostWrapper = styled.article`

progress{
    top:90px;
}



.img-wrapper{
    margin-top:150px;
    text-align:center;
    img{
        
    }
}
.gatsby-image-wrapper{
    max-height:500px;
}
.post-inner{
    margin-left: auto;
    max-width: 900px;
    margin-right: auto;
    padding: 20px 20px 20px 20px;

    .blog-post-wrapper{
        padding-right:7%;
        padding-left:7%;

        h1,h2,h3,h4{
            &:first-child{
                margin-bottom:40px;
            }
            &:not(:first-child){
                margin:40px 0; 
            }        
            position:relative;
            &:before{
                display: block;
                content: "#";
                height: 100%;
                width: 10px;
                opacity: .25;
                position: absolute;
                top: 0px;                
            }
        }
        h1{
            &:before{
                left: -40px;
            }
            @media(max-width:375px){
                font-size:30px;
            }
        }
        h2{
            &:before{
                left: -30px;
            }
        }

        p{
            color:rgb(60, 72, 86);
            font-size:16px;
            line-height:32px;
            font-weight:400;
        }      
        blockquote {
            padding-left: 90px;
            padding-bottom: 30px;
            background: transparent;
            overflow: hidden;
            border-radius: 6px;
            margin: 60px 0 40px 0;
            position: relative;
            box-sizing: border-box;
            position: relative;
            color: ${props => props.theme.black ? props.theme.black : '#333333'};
            &:before{
                content: '';
                width: 60px;
                height: 3px;
                background: ${props => props.theme.black ? props.theme.black : '#333333'};
                position: absolute;
                top: 20px;
                left: 0;
                
            }
            @media(max-width:450px){                                 
                padding-left:60px;
                &:before{
                    width:30px;       
                }
            }
            &:after{
                content: url(${Icon});
                position: absolute;
                right: 0;
                bottom: -22px;
                transform: rotate(180deg);
                z-index: -1;
                opacity: 1;

                transition: all 1s ease-in-out;
            }
            p{
                font-size: 32px;
                line-height: 45px;
            }
            span {
                display: block;
                font-size: 85%;
                margin-top: 10px;
                opacity: .8;
            }
        }
        pre{
            background: ${props => props.theme.secondaryLightColor};
            border-radius:15px;
            color:${props => props.theme.headingColor};
            code{
                font-weight:600;
                line-height:28px;
            }
        }
    }
}
`;

export default BlogPostWrapper;